<template>
  <div class="pageBack">
    <!--头部-->
    <div class="topDate">
      <el-image
          class="topDateImg"
          :src="require('@/assets/img/board/board.png')"
          :fit="'contain'"></el-image>
      <div class="topDateCont flex_b_c">
        <div class="dateTimeCls">
          <span>{{ dataDate }}</span>
          <span>{{ weekDay }}</span>
          <span>{{ timeH }}</span>
        </div>
        <div @click="returnPage" class="returnBut">返回</div>
      </div>
    </div>

    <!--主体-->
    <div class="flex_b_t contentdat">
      <div class="leftCont">
        <!--藏品数据分类-->
        <div class="moduleBack leftCont-item">
          <div class="legend" ref="collectionDataAnalysis"></div>
        </div>
        <!--总账据分类统计-->
        <div class="moduleBack moduleBack2">
          <div class="legend" ref="generalledgerStatistics"></div>
        </div>
        <!--藏品数据分类统计-->
        <div class="moduleBack moduleBack3">
          <div class="legend" ref="collClassificationStatistics"></div>
        </div>
      </div>

      <div class="middleCont">
        <div class="flex_b_c">
          <div class="dataTotal flex_l_c">
            <el-image
                class="dataTotalBack"
                :src="require('@/assets/img/board/totalback.png')"
                fit="contain"></el-image>
            <div>
              <div class="dataTotalNum">{{ collTotal }}</div>
              <div class="dataTotalName">藏品总数</div>
            </div>
          </div>
          <div class="dataTotal flex_l_c">
            <el-image
                class="dataTotalBack dataTotalBack2"
                :src="require('@/assets/img/board/totalback.png')"
                fit="contain"></el-image>
            <div>
              <div class="dataTotalNum">{{ resourcesTotal }}</div>
              <div class="dataTotalName">数字资源总数</div>
            </div>
          </div>
          <div class="dataTotal flex_l_c">
            <el-image
                class="dataTotalBack dataTotalBack3"
                :src="require('@/assets/img/board/totalback.png')"
                fit="contain"></el-image>
            <div>
              <div class="dataTotalNum">{{ filesTotal }}</div>
              <div class="dataTotalName">档案总数</div>
            </div>
          </div>
        </div>

        <!--藏品数字资源分析-->
        <div class="moduleBack moduleBack6">
          <!--藏品数字资源利用按月统计-->
          <div class="monthlyStatistics">
            <div class="monthlyStatisticsName">藏品数字资源利用按月统计</div>
            <div class="legend" style="margin-top: -2rem;height: 100%" ref="monthlyStatistics"></div>
          </div>

          <!--资源占比-->
          <div class="flex_b_c monthly">
            <div class="monthly-item">
              <div class="monthlyStatisticsName">图片资源占比</div>
              <div class="legend" ref="proportionOfImage"></div>
            </div>
            <div class="monthly-item">
              <div class="monthlyStatisticsName">三维资源占比</div>
              <div class="legend" ref="proportionOfThree"></div>
            </div>
            <div class="monthly-item">
              <div class="monthlyStatisticsName">其它资源占比</div>
              <div class="legend" ref="proportionOfOther"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="leftCont">
        <!--档案数据分类统计-->
        <div class="moduleBack moduleBack4 rightCont-item">
          <div class="legend" ref="archiveDataStatistics"></div>
        </div>
        <!--档案利用数年度分析-->
        <div class="moduleBack moduleBack5 rightCont-item">
          <div class="legend datawa">
            <div>
              <div class="flex_b_c titleName">
                <div class="name">今年借阅数（次）</div>
                <div>{{ nowYear }}</div>
              </div>
              <div class="gradient-text">
                <i v-for="item in nowYearNum" :key="item" class="iconfont icon-ren xiaoren"></i>
              </div>
            </div>
            <div>
              <div class="flex_b_c titleName magTop">
                <div class="name">去年借阅数（次）</div>
                <div>{{ lastYear }}</div>
              </div>
              <div class="gradient-text">
                <i v-for="item in lastYearNum" :key="item" class="iconfont icon-ren xiaoren"></i>
              </div>
            </div>
            <div>
              <div class="flex_b_c titleName magTop">
                <div class="name">累计借阅数（次）</div>
                <div>{{ accumulate }}</div>
              </div>
              <div class="gradient-text">
                <i v-for="item in accumulateNum" :key="item" class="iconfont icon-ren xiaoren"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "collectionOverview",
  data() {
    return {
      dataDate: '',
      weekDay: '',
      timeH: '',
      weekdays: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      dataInterval: null,

      collectionDataAnalysis: null,
      generalledgerStatistics: null,
      collClassificationStatistics: null,
      monthlyStatistics: null,
      proportionOfImage: null,
      proportionOfThree: null,
      proportionOfOther: null,
      archiveDataStatistics: null,

      nowYear: '',
      lastYear: '',
      accumulate: '',
      nowYearNum: 0,
      lastYearNum: 0,
      accumulateNum: 105,

      collTotal: '0',
      resourcesTotal: '0',
      filesTotal: '0',

    }
  },

  mounted() {
    this.getDataAnalysis()

    //获取时间
    this.getDateTime()

    //获取接口方法
    this.getPageDate()

    //监听浏览器变化
    window.onresize = () => {
      this.collectionDataAnalysis.resize()
      this.generalledgerStatistics.resize()
      this.collClassificationStatistics.resize()
      this.monthlyStatistics.resize()
      this.proportionOfImage.resize()
      this.proportionOfThree.resize()
      this.proportionOfOther.resize()
      this.archiveDataStatistics.resize()
    }
  },

  methods: {
    getPageDate() {
      this.getDataAnalysis()
      this.getLedgerStatistics()
      this.getClassStatistics()
      this.getArchiveDataStatistics()
      this.getArchiveUtilizeNumbers()
      this.getMonthlyStatistics()
      this.getProportionOfResources()
      this.getTotal()
    },

    //藏品数据分析
    getDataAnalysis() {
      this.$axios(this.api.collection.collectionDataAnalysis, {}, 'get').then((res) => {
        if (res.status) {
          let that = this
          let dataArr = []
          res.data.x.forEach((item, index) => {
            dataArr.push({
              name: item,
              value: res.data.ydata1[index]
            })
          })
          this.collectionDataAnalysis = this.$echarts.init(this.$refs.collectionDataAnalysis)

          let option = {
            color: ['#11FEFE', '#FFDD00'],
            //鼠标悬停提示
            tooltip: {
              show: true,
              trigger: 'item',
              formatter: '{b}: {c} ({d}%)',
              position: ['50%', '60%'],
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              },
              //解决鼠标悬停时窗口抖动问题
              transitionDuration: 0,
            },

            //图例
            legend: {
              scrollable: true,
              textStyle: {
                fontSize: 12,
                color: '#ffffff'
              },
              // height:'40px',
              // width: '60%',
              itemHeight: 8,
              itemWidth: 18,
              bottom: -6,
            },

            polar: {
              center: ['50%', '50%'],
            },
            angleAxis: {
              max: 100,
              show: false,
              startAngle: 0,
            },
            radiusAxis: {
              type: 'category',
              show: true,
              axisLabel: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false
              },
            },

            series: [
              {
                type: 'pie',
                //   roseType: 'radius',
                startAngle: 50,
                radius: '70%',
                center: ['50%', '50%'],
                itemStyle: {
                  borderWidth: '10',
                  color: 'pink',
                  borderColor: '#FFF',
                  normal: {
                    borderWidth: '5',
                    borderColor: '#00212D',
                  },
                },

                label: {
                  position: 'insideBottom',
                  color: '#D1CFCF',
                  fontSize: 12,
                  formatter: function (params) {
                    return params.name + '\n' + `${params.percent}%` + '\n' + `${params.value}`;
                  },
                },
                data: dataArr
              },
            ]
          };
          that.collectionDataAnalysis.setOption(option, true)
        }
      })
    },

    //总账数据分类统计
    getLedgerStatistics() {
      this.$axios(this.api.collection.collectionGeneralDataAnalysis, {}, 'get').then((res) => {
        if (res.status) {
          this.generalledgerStatistics = this.$echarts.init(this.$refs.generalledgerStatistics)
          const color = ['#FFDD00']; //线条边框颜色
          const typeList = res.data.x.map((item, index) => {
            item = item + `(${res.data.ydata1[index]})`
            return item
          })
          const number = res.data.ydata1
          const maxData = Math.max(...number) * 1.1;
          const tooltip = {
            show: true,
            textStyle: {
              color: '#ffffff',
              fontSize: 12,
            },
            //解决鼠标悬停时窗口抖动问题
            transitionDuration: 0,
          };
          const indicator = typeList.reduce((acc, cur) => {
            acc.push({name: cur, max: maxData,});
            return acc;
          }, []);
          const dataArr = [
            {
              name: '总账数据分类统计',
              value: number,
              symbolSize: 0,
              symbol: 'circle',
              lineStyle: {
                width: 1,
              },
              areaStyle: {
                // 单项区域填充样式
                color: {
                  type: 'linear',
                  x: 0, //右
                  y: 0, //下
                  x2: 1, //左
                  y2: 1, //上
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(255,221,0,.3)',
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(255,221,0,.3)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255,221,0,.3)',
                    },
                  ],
                  global: false,
                },
                opacity: 1, // 区域透明度
              },
            },
          ];

          let option = {
            color,
            tooltip,
            radar: {
              center: ['50%', '60%'], //圆心坐标距离左边和上边的距离
              radius: ['0%', '75%'], //内外半径，不写默认是75%
              startAngle: 90, //可以旋转图形
              shape: 'circle',
              indicator: indicator,
              splitArea: {
                show: false, //默认显示颜色分割区域，不需要显示
              },
              axisLine: {
                show: false, //是否显示十字交叉线
                symbol: ['none', 'circle'],
                symbolSize: [12, 12],

                lineStyle: {
                  color: '#fff',
                  width: 0,
                },
              },
              axisLabel: {show: false},
              splitLine: {
                //雷达一圈圈
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#186F7B', // 雷达一圈圈颜色分隔线颜色
                  width: 1, // 分隔线线宽
                },
              },
            },
            series: [
              {
                type: 'radar',
                data: dataArr,
              },
            ],
          };
          this.generalledgerStatistics.setOption(option, true)
        }
      })
    },

    //藏品数据分类统计
    getClassStatistics() {
      let that = this
      this.$axios(this.api.collection.collectionType2, {}, 'get').then((res) => {
        if (res.status) {
          let xArrList = res.data.x
          let yArrList = res.data.ydata1
          let colorq = [
            {
              c1: '#59B3F7',
              c2: '#7FFAFC',
            },
          ]
          this.collClassificationStatistics = this.$echarts.init(this.$refs.collClassificationStatistics)
          let opt = {
            //鼠标悬停提示
            tooltip: {
              trigger: 'axis',
              // formatter: '{b}: {c}',
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              },
              //解决鼠标悬停时窗口抖动问题
              transitionDuration: 0,
            },
            //坐标轴的刻度标签
            grid: {
              top: '15%',
              left: '3%',
              right: '4%',
              bottom: '8',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              offset: 8,
              axisTick: {
                show: false
              },
              axisLine: {
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                interval: 0,
                color: '#ffffff',
                fontSize: 12,
                formatter: function (params) {
                  var val = "";
                  // 超过四个字隐藏
                  if (params.length > 4) {
                    val = params.substr(0, 4) + '...';
                    return val;
                  } else {
                    return params;
                  }
                }
              },
              data: xArrList
            },
            yAxis: {
              type: 'value',
              nameTextStyle: {
                padding: [0, 40, 0, 0]
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                color: '#ffffff',
                fontSize: 12
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  width: 0.3,
                  color: '#ffffff'
                }
              }
            },
            series: {
              name: '藏品数',
              type: 'bar',
              barWidth: 20,  // 设置柱子宽度为30
              label: {
                show: false,
                color: '#ffffff',
                fontSize: 9,
                position: 'top', // 顶部显示
                formatter: '{c}', // 显示数据值
              },
              itemStyle: {
                normal: {
                  color: function (params) {
                    //自定义颜色
                    return new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                        offset: 0,
                        color: colorq[params.componentIndex].c1,
                      },
                      {
                        offset: 1,
                        color: colorq[params.componentIndex].c2,
                      },
                    ])
                  },
                  barBorderRadius: [5, 5, 0, 0], // 设置左上角和右上角的圆角为10，右下角和左下角的圆角为0
                },
              },
              data: yArrList,
            },
          }

          this.collClassificationStatistics.setOption(opt)
        }
      })
    },

    //档案数据分类统计
    getArchiveDataStatistics() {
      this.$axios(this.api.record.getArchivesBasicDataCount, {}, 'get').then((res) => {
        if (res.status) {
          console.log(res)
          this.archiveDataStatistics = this.$echarts.init(this.$refs.archiveDataStatistics)
          let title = '总数';
          let color = ['#FF7200', '#5460FF', '#17E984', '#FFDE00', '#7EFAFC', '#8A00E1', '#40c057', '#ffd351', '#ff8e43', '#f56b6d'];
          this.filesTotal = 0
          let echartData = res.data.map(item => {
            this.filesTotal += item.archivesDataCount
            return {
              name: item.archivesBasicName,
              value: item.archivesDataCount
            }
          })
          this.filesTotal = this.formatNumber(this.filesTotal)

          let formatNumber = function (num) {
            let reg = /(?=(\B)(\d{3})+$)/g;
            return num.toString().replace(reg, ',');
          }
          let total = echartData.reduce((a, b) => {
            return a + b.value * 1
          }, 0);

          let option = {
            color: color,
            tooltip: {
              trigger: 'item',
              formatter: '{b}: {c} ({d}%)',
              position: ['50%', '60%'],
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              },
              //解决鼠标悬停时窗口抖动问题
              transitionDuration: 0,
            },
            title: [
              {
                text: '{val|' + formatNumber(total) + '}' + '\n{name|' + title + '}',
                top: 'center',
                left: 'center',
                textStyle: {
                  rich: {
                    name: {
                      fontSize: 12,
                      fontWeight: 'normal',
                      color: '#CFD1D1',
                    },
                    val: {
                      fontSize: 16,
                      fontWeight: 'bolder',
                      color: '#FFFFFF',
                      padding: [10, 0]
                    }
                  }
                }
              },
            ],
            //图例
            legend: {
              // type: 'scroll', // 使用滚动的图例
              orient: 'horizontal',
              scrollable: true,
              textStyle: {
                fontSize: 12,
                color: '#ffffff',
              },
              // height:'40px',
              width: '90%',
              itemHeight: 8,
              itemWidth: 18,
              bottom: -6,
              formatter: function (name) {
                // 自定义图例文本内容
                let textLength = 5
                if (name.length > textLength) {
                  return name.substring(0, textLength) + '...';
                } else {
                  return name
                }
              }
            },
            series: [{
              type: 'pie',
              roseType: 'radius',
              radius: ['35%', '60%'],
              center: ['50%', '50%'],
              data: echartData,
              labelLine: {
                normal: {
                  length: 0,
                  length2: 10,
                  lineStyle: {
                    color: '#CFD0D1'
                  }
                }
              },
              label: {
                normal: {
                  formatter: params => {
                    return (
                        '{name|' + params.percent + '%}\n{value|' +
                        formatNumber(params.value) + '件}'
                    );
                  },
                  // padding: [0 , -100, 25, -100],
                  rich: {
                    name: {
                      fontSize: 12,
                      padding: [0, 0, 0, 10],
                      color: '#CFD0D1'
                    },
                    value: {
                      fontSize: 13,
                      // fontWeight: 'bolder',
                      padding: [10, 0, 0, 20],
                      color: 'inherit'
                      // color: '#333333'
                    }
                  }
                }
              },
            }]
          };

          this.archiveDataStatistics.setOption(option)
        }
      })
    },

    //档案利用数年度分析
    getArchiveUtilizeNumbers() {
      this.$axios(this.api.record.getArchivesBorrowCount, {}, 'get').then((res) => {
        if (res.status) {
          this.nowYear = this.formatNumber(res.data.thisYearCount)
          this.lastYear = this.formatNumber(res.data.lastYearCount)
          this.accumulate = this.formatNumber(res.data.totalCount)

          this.nowYearNum = Math.ceil(this.accumulateNum / res.data.totalCount * res.data.thisYearCount)
          this.lastYearNum = Math.ceil(this.accumulateNum / res.data.totalCount * res.data.lastYearCount)
        }
      })
    }
    ,

    //总数
    getTotal() {
      this.$axios(this.api.collection.collectionTotalCount, {}, 'get').then((res) => {
        if (res.status) {
          this.collTotal = this.formatNumber(res.data)
        }
      })
      this.$axios(this.api.collection.collectionResourceTotalCount, {}, 'get').then((res) => {
        if (res.status) {
          this.resourcesTotal = this.formatNumber(res.data.total)
        }
      })
    }
    ,

    //藏品数字资源利用按月统计
    getMonthlyStatistics() {
      this.$axios(this.api.collection.collectionResourceCountByMouth, {}, 'get').then((res) => {
        if (res.status) {
          this.monthlyStatistics = this.$echarts.init(this.$refs.monthlyStatistics)
          let xArrList = res.data.x
          let yArrList1 = res.data.ydata2
          let yArrList2 = res.data.ydata1
          let opt = {
            color: ['#7EFAFC', '#FFDE00'],
            //鼠标悬停提示
            tooltip: {
              trigger: 'axis',
              textStyle: {
                color: '#ffffff',
                fontSize: 12
              },
              //解决鼠标悬停时窗口抖动问题
              transitionDuration: 0,
            },

            //图例
            legend: {
              scrollable: true,
              textStyle: {
                fontSize: 12,
                color: '#ffffff'
              },
              itemHeight: 8,
              itemWidth: 18,
              bottom: '-2%',
            },


            //坐标轴的刻度标签
            grid: {
              top: '20%',
              left: '3%',
              right: '4%',
              bottom: '8%',
              containLabel: true
            },

            xAxis: {
              type: 'category',
              offset: 2,
              axisTick: {
                show: false
              },
              axisLine: {
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                // interval: 20,
                color: '#ffffff',
                fontSize: 12,
              },
              data: xArrList
            },
            yAxis: {
              type: 'value',
              nameTextStyle: {
                padding: [0, 40, 0, 0]
              },
              axisTick: {
                show: false
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 0.3,
                  color: '#ffffff'
                }
              },
              axisLabel: {
                color: '#ffffff',
                fontSize: 12
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  width: 0.3,
                  color: '#ffffff'
                }
              }
            },
            series: [
              {
                type: 'line',
                smooth: true,
                name: '去年',
                label: {
                  show: false,
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0, y: 0, x2: 0, y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(126,250,252, 0.8)' // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(126,250,252, 0)' // 100% 处的颜色
                    }]
                  }
                },
                data: yArrList1,
              },
              {
                type: 'line',
                smooth: true,
                name: '今年',
                label: {
                  show: false,
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0, y: 0, x2: 0, y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(255,222,0, 0.8)' // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(255,222,0, 0)' // 100% 处的颜色
                    }]
                  }
                },
                data: yArrList2,
              }
            ],
          }
          this.monthlyStatistics.setOption(opt)
        }
      })
    }
    ,

    getProportionOfResources() {
      this.$axios(this.api.collection.collectionResourceCount, {type: 0}, 'get').then((res) => {
        if (res.status) {
          this.setItemEcharts('proportionOfImage', res.data.count, res.data.total, '#079FFF')
        }
      })
      this.$axios(this.api.collection.collectionResourceCount, {type: 1}, 'get').then((res) => {
        if (res.status) {
          this.setItemEcharts('proportionOfThree', res.data.count, res.data.total, '#85FBF7')
        }
      })
      this.$axios(this.api.collection.collectionResourceCount, {type: 2}, 'get').then((res) => {
        if (res.status) {
          this.setItemEcharts('proportionOfOther', res.data.count, res.data.total, '#F9AB30')
        }
      })
    }
    ,

    setItemEcharts(refName, value, maxvalue, color) {
      let that = this
      this[refName] = this.$echarts.init(this.$refs[refName])

      let option = {
        title: {
          text: "{a|" + parseFloat((value / maxvalue * 100).toFixed(2)) + "}{c|%}",
          x: "center",
          y: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: 20,
                color: "#29EEF3",
                fontWeight: "bold",
              },
              c: {
                fontSize: 20,
                color: "#29EEF3",
                fontWeight: "bold",
              },
            },
          },
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["55%", "40%"],
            silent: true,
            clockwise: true,
            startAngle: 90,
            z: 0,
            zlevel: 0,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: value,
                name: "",
                itemStyle: {
                  normal: {
                    color: {
                      // 完成的圆环的颜色
                      colorStops: [
                        {
                          offset: 0,
                          color: color, // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: color, // 100% 处的颜色
                        },
                      ],
                    },
                  },
                },
              },
              {
                value: maxvalue - value,
                name: "",
                label: {
                  normal: {
                    show: false,
                  },
                },
                itemStyle: {
                  normal: {
                    color: "#173164",
                  },
                },
              },
            ],
          },
          {
            name: "",
            type: "gauge",
            radius: "55%",
            center: ["50%", "50%"],
            startAngle: 0,
            endAngle: 359.9,
            splitNumber: 8,
            hoverAnimation: true,
            axisTick: {
              show: false,
            },
            splitLine: {
              length: "110%",
              lineStyle: {
                width: 2,
                color: "#081325",
              },
            },
            axisLabel: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 0,
                name: "",
              },
            ],
          },
        ],
      };
      that[refName].setOption(option, true)
    }
    ,

    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    ,

    getDateTime() {
      let datad = new Date()
      let y = datad.getFullYear()
      let m = (datad.getMonth() + 1).toString().padStart(2, '0')
      let d = datad.getDate().toString().padStart(2, '0')
      this.dataDate = y + '年' + m + '月' + d + '日'
      this.weekDay = this.weekdays[datad.getDay()]
      let h = datad.getHours().toString().padStart(2, '0')
      let mm = datad.getMinutes().toString().padStart(2, '0')
      let s = datad.getSeconds().toString().padStart(2, '0')
      this.timeH = h + ':' + mm + ':' + s

      this.dataInterval = setInterval(() => {
        let datad = new Date()
        let y = datad.getFullYear()
        let m = (datad.getMonth() + 1).toString().padStart(2, '0')
        let d = datad.getDate().toString().padStart(2, '0')
        this.dataDate = y + '年' + m + '月' + d + '日'
        this.weekDay = this.weekdays[datad.getDay()]
        let h = datad.getHours().toString().padStart(2, '0')
        let mm = datad.getMinutes().toString().padStart(2, '0')
        let s = datad.getSeconds().toString().padStart(2, '0')
        this.timeH = h + ':' + mm + ':' + s
      }, 1000)
    }
    ,

    returnPage() {
      this.$router.back()
    }
    ,
  },

  beforeDestroy() {
    clearInterval(this.dataInterval)
  },
}
</script>

<style scoped lang="scss">
.pageBack {
  width: 100%;
  height: 100vh;
  background-color: #050A1C;
  position: relative;
}

.topDate {
  position: relative;

  .topDateImg {
    width: 100%;
  }

  .topDateCont {
    position: absolute;
    top: -0.7rem;
    left: 0;
    right: 0;
    font-weight: 400;
    font-size: 1.02rem;
    color: #FFFFFF;
    padding: 2.56rem 2.31rem 0;

    .dateTimeCls {
      span {
        margin-right: 3.3rem;
      }
    }

    .returnBut {
      cursor: pointer;
      position: relative;
      font-size: 0.8rem;
      padding: 0.2rem 2.2rem 0.2rem 0.6rem;
      border-radius: 0.25rem;
      border: 1px solid #74E7E9;

      &::before {
        content: '';
        position: absolute;
        background-image: url("~@/assets/img/board/return.png");
        display: inline-block;
        right: 0.6rem;
        top: 0.3rem;
        background-size: contain;
        background-repeat: no-repeat;
        width: 1rem;
        height: 1.5rem;
      }
    }
  }
}

.contentdat {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0 2rem 0;
}

.leftCont {
  width: 28%;
}

.middleCont {
  width: 42%;
}

.moduleBack {
  width: 100%;
  height: 27vh;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-image: url("~@/assets/img/board/itemBachImg1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-origin: border-box;

  .moduleBack-name {
    font-size: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    color: #11FEFE;
  }
}

.moduleBack2 {
  background-image: url("~@/assets/img/board/itemBachImg2.png");
}

.moduleBack3 {
  background-image: url("~@/assets/img/board/itemBachImg3.png");
}

.moduleBack4 {
  background-image: url("~@/assets/img/board/itemBachImg4.png");
}

.moduleBack5 {
  background-image: url("~@/assets/img/board/itemBachImg5.png");
}

.moduleBack6 {
  background-image: url("~@/assets/img/board/itemBachImg6.png");
  margin-top: 1rem;
  padding: 3.5rem 1rem 1rem;
  height: calc(81vh - 4.65rem);

  .monthlyStatistics {
    width: 100%;
    height: 55%;
    padding: 1rem;
    background: rgba(126, 250, 252, .3);
  }

  .monthly {
    margin-top: 1rem;
    height: 42.3%;

    .monthly-item {
      width: 31.8%;
      height: 100%;
      padding: 1rem;
      //background: rgba(126, 250, 252, .3);
      overflow: hidden;
      position: relative;

      .legend {
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 130%;
        height: 130%
      }

      .monthlyStatisticsName::before {
        left: 20%
      }
    }
  }

  .monthlyStatisticsName {
    font-size: 1rem;
    color: #F6FFFF;
    text-align: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: .6rem;
      height: .6rem;
      background: #72E3E6;
      top: 50%;
      left: 34.5%;
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
    }
  }
}

.rightCont-item {
  height: 41.5vh;
}

.legend {
  width: 100%;
  margin-top: 2rem;
  height: calc(100% - 2.7rem);
}

.datawa {
  padding: 1rem 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleName {
  font-size: 1rem;
  color: #FFFFFF;
  margin-bottom: .3rem;

  .name {
    font-size: 0.8rem;
  }
}

.magTop {
  margin-top: .8rem;
}

.gradient-text {
  background: linear-gradient(90deg, #7EFAFC, #FFE400);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  flex-wrap: wrap;

  .xiaoren {
    font-size: 1.1rem !important;
    margin: -0.14rem -.14rem;
  }
}

.dataTotal {
  width: 32%;
  padding: 1rem;
  //margin-bottom: 1rem;
  background-image: url("~@/assets/img/board/middleBack.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-origin: border-box;

  .dataTotalNum {
    font-size: 1.5rem;
    font-weight: bold;
    color: #7EFAFC;
  }

  .dataTotalName {
    font-size: 1rem;
    color: #FFFFFF;
  }
}

.dataTotalBack {
  width: 3.5rem;
  margin-right: 1.5rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 1.3rem;
    height: 1.3rem;
    background-image: url("~@/assets/img/board/totalIcon1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-origin: border-box;
  }
}

.dataTotalBack2::before {
  background-image: url("~@/assets/img/board/totalIcon2.png");
}

.dataTotalBack3::before {
  background-image: url("~@/assets/img/board/totalIcon3.png");
}
</style>